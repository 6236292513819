import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";

import typography from "@tailwindcss/typography";

export default <Config>{
  content: [
    "components/**/*.{vue,js,ts}",
    "layouts/**/*.vue",
    "pages/**/*.vue",
    "composables/**/*.{js,ts}",
    "plugins/**/*.{js,ts}",
    "App.{js,ts,vue}",
    "app.{js,ts,vue}",
    "Error.{js,ts,vue}",
    "error.{js,ts,vue}",
    "content/**/*.md",
  ],
  theme: {
    colors: {
      inherit: "inherit",
      current: "currentColor",
      transparent: "transparent",

      black: "#000",
      white: "#fff",

      blue: {
        DEFAULT: "#95aad2",
        "50": "#f3f6fa",
        "100": "#e9eff6",
        "200": "#d6e1ef",
        "300": "#bdcee4",
        "400": "#95aad2",
        "500": "#8a9bca",
        "600": "#7280b9",
        "700": "#606ca2",
        "800": "#505a83",
        "900": "#454d6a",
        "950": "#282c3e",
      },

      rose: {
        DEFAULT: "#e39394",
        "50": "#fcf4f4",
        "100": "#f9e7e7",
        "200": "#f5d3d3",
        "300": "#edb4b5",
        "400": "#e39394",
        "500": "#d16263",
        "600": "#bc4647",
        "700": "#9d3839",
        "800": "#833132",
        "900": "#6e2e2f",
        "950": "#3b1414",
      },

      green: {
        DEFAULT: "#97a193",
        "50": "#f6f7f6",
        "100": "#e3e6e1",
        "200": "#c6ccc3",
        "300": "#97a193",
        "400": "#7d8879",
        "500": "#626d5f",
        "600": "#4d574a",
        "700": "#40473e",
        "800": "#353b34",
        "900": "#2f332e",
        "950": "#181b18",
      },

      yellow: {
        DEFAULT: "#f7daa4",
        "50": "#fef9ee",
        "100": "#fcf1d8",
        "200": "#f7daa4",
        "300": "#f3c77e",
        "400": "#eda64a",
        "500": "#e98c26",
        "600": "#da731c",
        "700": "#b55819",
        "800": "#90461c",
        "900": "#743b1a",
        "950": "#3f1c0b",
      },

      darkblue: {
        DEFAULT: "#ccbed7",
        "50": "#f9f7fb",
        "100": "#f4f1f6",
        "200": "#eae5ef",
        "300": "#dad0e2",
        "400": "#ccbed7",
        "500": "#ad95bd",
        "600": "#9b7cab",
        "700": "#896a97",
        "800": "#73587f",
        "900": "#604969",
        "950": "#3e2f46",
      },

      danger: {
        DEFAULT: "#ef4444",
        "50": "#fef2f2",
        "100": "#fee2e2",
        "200": "#fecaca",
        "300": "#fca5a5",
        "400": "#f87171",
        "500": "#ef4444",
        "600": "#dc2626",
        "700": "#b91c1c",
        "800": "#991b1b",
        "900": "#7f1d1d",
        "950": "#450a0a",
      },

      grey: {
        DEFAULT: "#64748b",
        "50": "#f8fafc",
        "100": "#f1f5f9",
        "200": "#e2e8f0",
        "300": "#cbd5e1",
        "400": "#94a3b8",
        "500": "#64748b",
        "600": "#475569",
        "700": "#334155",
        "800": "#1e293b",
        "900": "#0f172a",
        "950": "#020617",
      },

      copperfield: {
        DEFAULT: "#E29873",
        50: "#FBEFE9",
        100: "#F7E3D8",
        200: "#F0CAB7",
        300: "#E9B195",
        400: "#E29873",
        500: "#D87645",
        600: "#BE5927",
        700: "#8F431E",
        800: "#612E14",
        900: "#32180A",
        950: "#1B0D06",
      },

      mongoose: {
        DEFAULT: "#BEA681",
        50: "#EFE9DF",
        100: "#E8DFD2",
        200: "#DACCB7",
        300: "#CCB99C",
        400: "#BEA681",
        500: "#AB8C5C",
        600: "#886E46",
        700: "#635033",
        800: "#3E3320",
        900: "#19150D",
        950: "#070604",
      },
    },

    fontFamily: {
      // body: ['"Montserrat"', defaultTheme.fontFamily.sans],
      body: ['"DM Sans"', defaultTheme.fontFamily.sans],
      display: ['"Allusion Display"', defaultTheme.fontFamily.sans],
    },

    container: { screens: {} },

    extend: {
      animation: {
        "spin-fast": "spin 0.25s linear infinite",
        "marker-bounce": "marker-bounce 1s infinite",
      },
      keyframes: {
        "marker-bounce": {
          "0%, 100%": {
            transform: "translateY(100%) translateX(-50%)",
            animationTimingFunction: "cubic-bezier(0,0,0.2,1)",
          },
          "50%": {
            transform: "translateY(75%) translateX(-50%)",
            animationTimingFunction: "cubic-bezier(0.8,0,1,1)",
          },
        },
      },
    },
  },
  plugins: [typography],
};

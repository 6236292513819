<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps<{ error: NuxtError }>();

const localePath = useLocalePath();

const handleError = () => {
  clearError({ redirect: localePath({ name: "index" }) });
};

const title = computed(() => {
  switch (props.error.message) {
    case "PAGE_NOT_FOUND":
      return "Ta strona zaginęła!";
    case "POST_NOT_FOUND":
      return "Ta inspiracja zaginęła!";
    case "BOARD_NOT_FOUND":
      return "Ta tablica zaginęła!";
    case "INVALID_CREDENTIALS":
      return "Nieprawidłowe dane logowania";
    case "INVALID_PROVIDER":
      return "Nieprawidłowy dostawca logowania";
    default:
      return "Coś poszło nie tak";
  }
});

const subtitle = computed(() => {
  switch (props.error.message) {
    case "PAGE_NOT_FOUND":
      return "Ale nie martw się, mamy jeszcze wiele innych pomysłów!";
    case "POST_NOT_FOUND":
      return "Ale nie martw się, mamy jeszcze wiele innych pomysłów!";
    case "BOARD_NOT_FOUND":
      return "Ale nie martw się, mamy jeszcze wiele innych pomysłów!";
    case "INVALID_CREDENTIALS":
      return "Sprawdź czy wprowadziłeś poprawne dane logowania";
    case "INVALID_PROVIDER":
      return "Konto o tym adresie email zostało zarejestrowane za pomocą innego dostawcy logowania";
    default:
      return "Spróbuj ponownie później";
  }
});
</script>

<template>
  <AppWrapper>
    <NuxtLayout>
      <PageHeader>
        <template #end>
          <PageTitle
            default="błąd"
            :append="props.error.statusCode.toString()"
          />
        </template>
      </PageHeader>

      <div class="error-content">
        <div class="error-content__container p-5 lg:py-10">
          <div
            class="error-content__wrapper flex flex-col items-stretch gap-4 max-w-[37rem] mx-auto"
          >
            <div class="error-content__image">
              <NuxtPicture
                src="/img/404.jpeg"
                role="presentation"
                alt=""
                width="400"
                height="400"
                class="block aspect-square relative"
                :img-attrs="{
                  class: 'block absolute inset-0 w-full h-full object-cover',
                }"
                sizes="100vw xs:390px sm:640px md:768px lg:512px xl:720px xxl:592px"
                quality="75"
              />
            </div>
            <div class="error-content__main">
              <p
                class="text-[1.75rem] lg:text-[2rem] leading-[2.75rem] text-center text-balance"
              >
                <span class="italic">{{ title }}</span>
                <component is="br" />
                <span class="font-display">{{ subtitle }}</span>
              </p>
            </div>
            <div
              class="error-content__actions flex items-center justify-center"
            >
              <AppBtn @click="handleError" variant="solid">
                <span>Przejdź do strony głównej</span>
              </AppBtn>
            </div>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </AppWrapper>
</template>

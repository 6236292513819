import type { SimpleGeometry } from "@directus/types";

export enum PostType {
  Inspiration = "inspiration",
  Professional = "professional",
  Architect = "architect",
  Relaxation = "relaxation",
  Contractor = "contractor",
  Project = "project",
}

export interface FileItem extends Record<string, any> {
  id?: string;
  description?: string;
  width?: number;
  height?: number;
  type?: string;
}

export interface ImageItem extends Record<string, any> {
  id?: string;
  file?: FileItem;
  caption?: string;
}

export interface VideoItem extends Record<string, any> {
  id?: string;
  file?: FileItem;
  caption?: string;
  poster?: FileItem;
}

export interface PostImageItem extends Record<string, any> {
  id?: string;
  sort?: number;
  images_id?: ImageItem;
  posts_id?: PostItem;
}

export interface PostVideoItem extends Record<string, any> {
  id?: string;
  sort?: number;
  videos_id?: VideoItem;
  posts_id?: PostItem;
}

export interface TextContentItem extends Record<string, any> {
  id?: string;
  value?: string;
}

export interface TitleContentItem extends Record<string, any> {
  id?: string;
  level?: "h2" | "h3";
  type?: "normal" | "decorative" | "mixed";
  value_normal?: string;
  value_decorative?: string;
}

export interface BreakContentItem extends Record<string, any> {
  id?: string;
}

export interface PostTagItem extends Record<string, any> {
  id?: string;
  sort?: number;
  tags_id?: TagItem;
}

export interface TagItem extends Record<string, any> {
  id?: string;
  title?: string;
  filters?: PostType[];
}

export interface PostContentItemBase extends Record<string, any> {
  id?: string;
  post_id?: PostItem;
  sort?: number;
  // dynamic
  collection?: string;
  item?: any;
}

export interface PostContentImageItem extends PostContentItemBase {
  collection: "images";
  item?: ImageItem;
}

export interface PostContentVideoeItem extends PostContentItemBase {
  collection: "videos";
  item?: VideoItem;
}

export interface PostContentTextItem extends PostContentItemBase {
  collection: "content_text";
  item?: TextContentItem;
}

export interface PostContentTitleItem extends PostContentItemBase {
  collection: "content_title";
  item?: TitleContentItem;
}

export interface PostContentBreakItem extends PostContentItemBase {
  collection: "content_break";
  item?: BreakContentItem;
}

export type PostContentItem =
  | PostContentTextItem
  | PostContentImageItem
  | PostContentVideoeItem
  | PostContentTitleItem
  | PostContentBreakItem;

export interface AuthorItem extends Record<string, any> {
  id?: string;
  image?: FileItem;
  name?: string;
  role?: string;
  description?: string;
  // posts?: PostItem[];
}

export interface PostRelatedItem extends Record<string, any> {
  id?: string;
  posts_id?: PostItem;
  related_posts_id?: PostItem;
  sort?: number;
}

export interface PostItem extends Record<string, any> {
  id?: string;
  type?: PostType;
  title?: string;
  slug?: string;
  subtitle_type?: "subtitle" | "architects";
  subtitle?: string;
  logo?: FileItem;
  tags?: PostTagItem[];
  cover?: ImageItem;

  architect_name?: string;
  architect_link?: string;

  architects?: {
    name?: string;
    link?: string;
  }[];

  photographer_name?: string;
  photographer_link?: string;

  photographers?: {
    name?: string;
    link?: string;
  }[];

  website_name?: string;
  website_link?: string;

  social_links?: {
    name?: string;
    link?: string;
  }[];

  location_address?: string;

  project_details_enabled?: boolean;

  project_details_title_normal?: string;
  project_details_title_decorative?: string;

  project_details_price_value?: string;
  project_details_price_currency?: string;

  project_details_area_data?: {
    type: "building_area" | "usable_area" | "pitch_angle";
    value: number;
  }[];

  project_details_specification_value?: string;

  content?: PostContentItem[];

  cta_enabled?: boolean;
  cta_title_normal?: string;
  cta_title_decorative?: string;
  cta_content?: string;
  cta_actions?: {
    type: "phone" | "email" | "location";
    value: string;
  }[];

  images?: PostImageItem[];
  videos?: PostVideoItem[];

  location_map_enabled?: boolean;
  location_map_marker?: SimpleGeometry;

  author?: AuthorItem;
  related?: PostRelatedItem[];
}

export const usePosts = () => {
  const request = useDirectus();
  const { getItems } = useDirectusItems();

  /**
   * Default posts object for reactive properties initialization
   */
  const defaultPosts = {
    data: [] as PostItem[],
    meta: { total_count: 0, filter_count: 0 },
  };

  /**
   * Post field presets
   */
  const postFieldPresets: Record<string, string[]> = {
    listing: [
      "id",
      "type",
      "title",
      "slug",
      "subtitle_type",
      "subtitle",
      "architect_name",
      "architects",
      "cover.*.*",
      "logo.*",
      "tags.sort",
      "tags.tags_id.title",
      "location_map_enabled",
      "location_map_marker",
      "date_published",
      "strategic",
    ],

    content: [
      "*",
      "tags.*.*",
      "logo.*",
      "cover.*.*",
      "content.id",
      "content.sort",
      "content.collection",
      "content.item.*.*",
      "author.*.*",
      "related.*.*",
      "images.*.*.*",
      "videos.*.*.*.*",
      "related.*.cover.*.*",
      "related.*.logo.*.*",
    ],
  };

  /**
   * Get post by slug
   * @param {MaybeRef<string>} slug the post slug
   * @param {Object} options the options object
   * @param {MaybeRef<PostType> | undefined} options.type the post type
   * @param {MaybeRef<string[]> | undefined} options.fields the fields to get
   * @returns {Promise<PostItem | null>} the post
   */
  const getPost = async (
    slug: MaybeRef<string>,
    options: {
      type?: MaybeRef<PostType>;
      fields?: MaybeRef<string[]>;
    } = {},
    token?: MaybeRef<string>
  ): Promise<PostItem | null> => {
    const type = unref(options.type) || undefined;
    const fields = unref(options.fields) || [];

    const filter = { slug: { _eq: unref(slug) }, type: { _eq: type } };
    const limit = 1;

    const access_token = unref(token);

    return request<{ data: PostItem[] }>(
      "/items/posts",
      { query: { filter, fields, limit, access_token } },
      true
    ).then((response) => {
      return response?.data?.[0] || null;
    });
  };

  /**
   * Get posts
   * @param {Object} options the options object
   * @param {MaybeRef<PostType> | undefined} options.type the post type
   * @param {MaybeRef<number> | undefined} options.page the page number
   * @param {MaybeRef<number> | undefined} options.limit the limit
   * @param {MaybeRef<string> | undefined} options.sort the sort
   * @param {MaybeRef<string[]> | undefined} options.fields the fields to get
   * @returns {ReturnType<typeof getItems>} the posts
   */
  const getPosts = async (
    options: {
      type?: MaybeRef<PostType>;
      page?: MaybeRef<number>;
      limit?: MaybeRef<number>;
      sort?: MaybeRef<string>;
      fields?: MaybeRef<string[]>;
      filter?: MaybeRef<Record<string, unknown>>;
    } = {}
  ): ReturnType<typeof getItems<PostItem>> => {
    const type = unref(options.type) || undefined;
    const page = unref(options.page) || 1;
    const limit = unref(options.limit) || 10;
    const fields = unref(options.fields) || [];

    const sort = unref(options.sort) || "-date_published";

    const filter = {
      _and: [{ type: { _eq: type } }, unref(options.filter)].filter(Boolean),
    };

    const meta = "*";

    return getItems<PostItem>({
      collection: "posts",
      params: { filter, fields, page, limit, sort, meta },
    });
  };

  /**
   * Get adjacent post
   * @param {MaybeRef<"next" | "prev">} direction the direction
   * @param {MaybeRef<PostItem>} post the post to get adjacent post from
   * @param {Object} options the options object
   * @param {MaybeRef<PostType> | undefined} options.type the post type
   * @param {MaybeRef<string[]> | undefined} options.fields the fields to get
   * @returns {Promise<PostItem | null>} the adjacent post
   */
  const getAdjacentPost = async (
    direction: MaybeRef<"next" | "prev">,
    post: MaybeRef<PostItem>,
    options: {
      type?: MaybeRef<PostType>;
      fields?: MaybeRef<string[]>;
    } = {}
  ): Promise<PostItem | null> => {
    const type = unref(options.type) || undefined;
    const fields = unref(options.fields) || [];

    const date = unref(post)?.date_published;

    const filter = {
      type: { _eq: type },
      date_published: {
        [direction === "prev" ? `_lt` : "_gt"]: date,
      },
    };

    const sort = direction === "prev" ? "-date_published" : "date_published";

    return getItems<PostItem>({
      collection: "posts",
      params: { filter, fields, limit: 1, sort },
    }).then((response) => {
      return response?.[0] || null;
    });
  };

  /**
   * Post type labels
   */
  const postTypeLabels = {
    [PostType.Inspiration]: "Inspiracja",
    [PostType.Professional]: "Partner",
    [PostType.Architect]: "Architekt",
    [PostType.Relaxation]: "Relaks",
    [PostType.Contractor]: "Wykonawca",
    [PostType.Project]: "Gotowy projekt",
  };

  /**
   * Get post type label
   * @param {MaybeRef<PostType>} type the post type
   * @returns {string} the post type label
   */
  const getPostTypeLabel = (type: MaybeRef<PostType>): string => {
    return postTypeLabels[unref(type)];
  };

  const localePath = useLocalePath();
  const { getPostTypeSlug } = usePostTypes();

  /**
   * Get post link
   * @param {MaybeRef<PostType>} type the post type
   * @param {MaybeRef<string>} slug the post slug
   * @returns {string} the post link
   */
  const getPostLink = (
    type: MaybeRef<PostType>,
    slug: MaybeRef<string>
  ): string => {
    const typeSlug = getPostTypeSlug(unref(type));

    return localePath({
      name: "type-slug",
      params: { type: typeSlug, slug: unref(slug) },
    });
  };

  return {
    defaultPosts,
    postFieldPresets,

    getPost,
    getPosts,

    getAdjacentPost,

    getPostTypeLabel,

    getPostLink,
  };
};

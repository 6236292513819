import revive_payload_client_fj6XKDqe2S from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5rTeCYJYoA from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rLIyqY8NKF from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_HUJTnEkeZ3 from "/src/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.18.0_vite@5.4.6_@types+node@22.5.5_sass-embed_5pw73bpbgg3vtnlstq4266n2w4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_E3QTG2lIEC from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_T4QlRSWTNX from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bPSWQbgsvv from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KCaA2eJR16 from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OfwQB5kKlP from "/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.18.0_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import _64nuxt_scripts_D34jXE4xNh from "/src/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_7gVQZpVNsl from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_vkskjgu6roybk3r7osf6eejrfq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3py1WG98Vt from "/src/node_modules/.pnpm/nuxt-directus@5.6.1_magicast@0.3.5_rollup@4.18.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import nuxt_plugin_oyEG8UdHSM from "/src/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.18.0_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Z1jqh1gsLe from "/src/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.18.0_vite@5.4._l5ac5bolp5vdea7hd6wzzdajra/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_uQyARZg1Sn from "/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.18.0_vite@5.4.6_@types+node@22.5.5_sass-embedded@1.7_xf35yaufszchdqmfzjzgruv5hy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_xaq0sZL4wG from "/src/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.7_magicast@0.3.5_rollup@4.18.0_webpack-sources@3.2.3/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import titles_GnrHd44Hjr from "/src/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.18.0_vite@5.4.6_@types+node@22.5.5__oqz2n6wm7zit6andmkumjwaqau/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_cAWVuOXeXu from "/src/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.18.0_vite@5.4.6_@types+node@22.5.5__oqz2n6wm7zit6andmkumjwaqau/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_VB8xEsjZ1t from "/src/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.18.0_vite@5.4.6_@types+node@22.5.5_sass-em_v3gxjwxlrjx6nwua6cg737c5xe/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_8Pn3ApyeE7 from "/src/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.18.0_vite@5.4.6_@types+node@22.5.5_sass-em_v3gxjwxlrjx6nwua6cg737c5xe/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_3xH0v74gpW from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.18.0_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sCENV0HcoR from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.18.0_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Pjxo7xn5Ht from "/src/node_modules/.pnpm/@nuxtjs+device@3.2.3_@parcel+watcher@2.4.1_@types+node@22.5.5_h3@1.12.0_ioredis@5.4.1_jiti@1._gjcizeauy33u627wbfez33qibe/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import vue_masonry_wall_13R6vu58Yn from "/src/plugins/vue-masonry-wall.ts";
export default [
  revive_payload_client_fj6XKDqe2S,
  unhead_5rTeCYJYoA,
  router_rLIyqY8NKF,
  _0_siteConfig_HUJTnEkeZ3,
  payload_client_E3QTG2lIEC,
  navigation_repaint_client_T4QlRSWTNX,
  check_outdated_build_client_bPSWQbgsvv,
  chunk_reload_client_KCaA2eJR16,
  plugin_vue3_OfwQB5kKlP,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7gVQZpVNsl,
  plugin_3py1WG98Vt,
  nuxt_plugin_oyEG8UdHSM,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Z1jqh1gsLe,
  plugin_uQyARZg1Sn,
  plugin_client_xaq0sZL4wG,
  titles_GnrHd44Hjr,
  defaultsWaitI18n_cAWVuOXeXu,
  siteConfig_VB8xEsjZ1t,
  inferSeoMetaPlugin_8Pn3ApyeE7,
  switch_locale_path_ssr_3xH0v74gpW,
  i18n_sCENV0HcoR,
  plugin_Pjxo7xn5Ht,
  vue_masonry_wall_13R6vu58Yn
]
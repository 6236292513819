import { withQuery, withBase } from "ufo";

export enum AuthView {
  Login = "login",

  Register = "register",
  Confirm = "confirm",

  Reset = "reset",
  Recover = "recover",
}

export const useAuth = createSharedComposable(() => {
  const isAuthDialogOpen = useState("isAuthDialogOpen", () => false);

  const confirmationToken = useState<string | undefined>(
    "confirmationToken",
    undefined
  );
  const authView = useState<AuthView>("authView", () => AuthView.Login);

  const openAuthDialog = (tab?: MaybeRef<AuthView>) => {
    isAuthDialogOpen.value = true;
    authView.value = unref(tab) || AuthView.Login;
  };

  const request = useDirectus();

  const config = useRuntimeConfig();

  const {
    login,
    loginWithProvider,
    logout,
    requestPasswordReset,
    resetPassword,
    fetchUser,
    setUser,
  } = useDirectusAuth();

  const { updateUser, deleteUsers } = useDirectusUsers();

  const isLoggedIn = ref(false);

  const currentUser = useDirectusUser();

  const updateIsLoggedIn = () => (isLoggedIn.value = !!currentUser.value);

  updateIsLoggedIn();
  watch(currentUser, updateIsLoggedIn);
  tryOnMounted(updateIsLoggedIn);

  const route = useRoute();
  const router = useRouter();

  const localePath = useLocalePath();

  const redirectUri = computed(() =>
    withQuery("/auth", { next: route.fullPath })
  );

  const loginWithGoogle = async () => {
    return await loginWithProvider("google", redirectUri.value)
      .then((result) => {
        isAuthDialogOpen.value = false;
        return result;
      })
      .catch(() => {});
  };

  const loginWithFacebook = async () => {
    return await loginWithProvider("facebook", redirectUri.value)
      .then((result) => {
        isAuthDialogOpen.value = false;
        return result;
      })
      .catch(() => {});
  };

  const loginWithEmail = async (
    email: string,
    password: string,
    otp?: string
  ) => {
    return await login({ email, password, otp });
  };

  const registerWithEmail = async (
    first_name: string,
    email: string,
    password: string
  ) => {
    return await request("/users/register", {
      method: "POST",
      body: {
        first_name,
        email,
        password,
        verification_url: withBase("/verify-email", config.public.baseUrl),
      },
    });
  };

  const updateUserName = async (name: string) => {
    if (currentUser.value) {
      return updateUser({
        id: currentUser.value.id,
        user: { first_name: name },
      });
    }
  };

  const updateUserEmail = async (email: string) => {
    if (currentUser.value) {
      return updateUser({
        id: currentUser.value.id,
        user: { email },
      });
    }
  };

  const updateUserPassword = async (password: string) => {
    if (currentUser.value) {
      return updateUser({
        id: currentUser.value.id,
        user: { password },
      });
    }
  };

  const deleteUser = async () => {
    if (currentUser.value) {
      return deleteUsers({ users: [currentUser.value.id] });
    }
  };

  const confirmEmail = async (token: string) => {
    return await request("/users/register/verify-email", {
      method: "GET",
      query: { token },
    });
  };

  const logoutUser = async () => {
    await logout();

    if (
      Array.isArray(route.meta.middleware)
        ? route.meta.middleware.includes("auth")
        : route.meta.middleware === "auth"
    ) {
      await navigateTo(localePath({ name: "index" }));
    }
  };

  const refreshUser = async () => {
    try {
      const user = await fetchUser();
      setUser(user.value);
      return user.value;
    } catch (error) {
      await logoutUser();
      currentUser.value = null;
      return null;
    }
  };

  const updateConsents = async (
    data?: MaybeRef<{
      general?: MaybeRef<string[]>;
      marketing?: MaybeRef<string[]>;
    }>
  ) => {
    if (!currentUser.value) return;

    data = unref(data);

    const user = {
      general_consents: unref(data?.general),
      marketing_consents: unref(data?.marketing),
    };

    return await updateUser({ id: currentUser.value?.id, user });
  };

  const strongPasswordRegex =
    /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\s).*$/;

  const isPasswordStrong = (password: string) => {
    return strongPasswordRegex.test(password);
  };

  return {
    isAuthDialogOpen,

    confirmationToken,
    authView,

    currentUser,
    isLoggedIn,

    redirectUri,

    openAuthDialog,

    loginWithGoogle,
    loginWithFacebook,
    loginWithEmail,

    registerWithEmail,

    requestPasswordReset,
    resetPassword,

    confirmEmail,

    logoutUser,

    updateUserName,
    updateUserEmail,
    updateUserPassword,

    deleteUser,

    refreshUser,

    updateConsents,

    strongPasswordRegex,
    isPasswordStrong,
  };
});
